.divider {
   background: #f3f3f3;
   display: block;
   height: 2px;
   margin: 30px 0;
   width: 70px;
}

@media screen and (min-width: 1000px) {

   .divider {
      margin: 60px 0;
   }

}
