.context {
   margin: 30px 0;
}

.context-line {
   font-family: 'Manrope-ExtraBold';
   font-size: 14px;
   letter-spacing: 1px;
   margin: 0;
   padding: 1px 0;
}

@media screen and (min-width: 1000px) {

   .context {
      margin: 60px 0;
   }

   .context-line {
      font-size: 16px;
   }

}
