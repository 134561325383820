.header {
   background: transparent;
   height: 90px;
   position: absolute;
   left: 0;
   top: 0;
   transition: all 0ms ease-in-out 800ms;
   width: 100%;
   z-index: 4;
}

.overlay-open .header {
   background: #111;
   transition: all 800ms ease-in-out 800ms;
}

.header-inner {
   align-items: center;
   display: flex;
   height: 100%;
   margin: 0 auto;
   max-width: 1400px;
   padding: 0 30px;
   width: 100%;
}

.button-back {
   background: url('../../../images/icons/arrow-left-white.svg') left center no-repeat;
   background-size: auto 11px;
   color: #f3f3f3;
   display: inline-block;
   font-family: "Manrope-Extrabold";
   padding: 5px 5px 5px 21px;
   text-decoration: none;
   transition: all 900ms ease-in-out;
   z-index: 1;
}

.button-back:hover {
   padding-left: 23px;
}

.hamburger {
   cursor: pointer;
   display: flex;
   flex-direction: column;
   height: 35px;
   justify-content: space-between;
   margin-left: auto;
   padding: 5px;
   width: 40px;
}

.hamburger-line {
   background: #f9f9f9;
   height: 3px;
   transition: all 300ms ease-in-out;
   width: 100%;
}

.overlay-open .hamburger-line:nth-child(1) {
   transform: translateY(11px) rotate(45deg);
}

.overlay-open .hamburger-line:nth-child(2) {
   opacity: 0;
}

.overlay-open .hamburger-line:nth-child(3) {
   transform: translateY(-11px) rotate(-45deg);
}

@media screen and (min-width: 1000px) {

   .header-inner {
      padding: 0 60px;
   }



}
