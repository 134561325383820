.previews {
   display: flex;
   flex-direction: column;
}


@media screen and (min-width: 1000px) {

   .previews {

   }

}

@media screen and (min-width: 1200px) {

   .previews {
      flex-direction: row;
      flex-wrap: wrap;
   }
   
}
