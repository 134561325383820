html {
   box-sizing: border-box;
   font-family: 'Manrope-Medium';
   -webkit-font-smoothing: antialiased;
   font-smoothing: antialiased;
   height: 100%;

}
*, *:before, *:after {
   box-sizing: inherit;
}

body {
   background: #111;
   color: #f3f3f3;
   font-size: 16px;
   line-height: 1.4;
}

/* .App::before {
   background: #111;
   content: '';
   display: block;
   height: 48vh;
   position: absolute;
   top: 52vh;
   width: 100%;
   z-index: 2;
} */

@media screen and (min-width: 1000px) {

   body.overlay-open {
      overflow: hidden;
      position: relative;
   }

   .section-title {
      font-size: 34px;
   }

}
