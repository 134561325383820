.masthead-video {
   background: #111;
   height: 450px;
   left: 0;
   opacity: 1;
   overflow: hidden;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: -1;
}

.masthead-video-clip {
   right: 0;
   min-height: 450px;
   min-width: 100%;
   position: absolute;
   top: 0;
   transform: translate(21%, -25%);
}

.masthead-video-clip.base {

}

.masthead-video-clip.middle {

}

.masthead-video-clip.top {

}


@media screen and (min-width: 1000px) {

   .masthead-video {
      height: 52vh;
   }

   .masthead-video-clip {
      right: 0;
      min-height: 500px;
      top: 0;
      transform: translate(1%, -20%);
   }

}
