.container {

}

.container-inner {
   margin: 0 auto;
   max-width: 1400px;
   padding: 30px;
   position: relative;
   width: 100%;
   z-index: 3;
}

body.overlay-open .container {

}

@media screen and (min-width: 1000px) {

   .container-inner {
      padding: 60px;
   }

}
