/* CLAYTON specific adjustments --------------------------------------------- */

.clayton .masthead-text-header {

}

@media screen and (min-width: 1000px) {

   .clayton .masthead-text-header {
      max-width: 490px;
   }

   .clayton .leader-line {
      max-width: 650px;
   }

}


/* BEATS specific adjustments ----------------------------------------------- */

.beats .masthead-text-header {
   max-width: 210px;
}

@media screen and (min-width: 1000px) {

   .beats .masthead-text-header {
      max-width: 390px;
   }

   .beats .leader {
      max-width: 630px;
   }

}
