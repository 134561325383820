.bottom-nav {
   font-family: "Manrope-Extrabold";
   display: flex;
   width: 100%;
}

.bottom-nav .button-prev, .bottom-nav .button-next {
   color: #f3f3f3;
   display: inline-block;
   text-decoration: none;
   transition: all 300ms ease-in-out;
}

.bottom-nav .button-prev {
   background: url('../../../images/icons/arrow-left-white.svg') left center no-repeat;
   background-size: auto 10px;
   padding: 10px 10px 10px 21px;
}

.bottom-nav .button-next {
   background: url('../../../images/icons/arrow-right-white.svg') right center no-repeat;
   background-size: auto 10px;
   margin-left: auto;
   padding: 10px 21px 10px 10px;
}

.bottom-nav .button-prev:hover {
   padding-left: 23px;
}

.bottom-nav .button-next:hover {
   padding-right: 23px;
}

@media screen and (min-width: 1000px) {

   .bottom-nav {
      margin: 60px 0;
   }

}
