.overlay {
   background: #111;
   height: 100%;
   opacity: 0;
   pointer-events: none;
   position: fixed;
   top: 0;
   transition: opacity 800ms ease-in-out 800ms;
   width: 100%;
   z-index: 3;
}

.overlay-inner {
   height: 100%;
   margin: 0 auto;
   max-width: 1400px;
   opacity: 0;
   overflow: auto;
   padding: 120px 30px 30px 30px;
   transition: all 800ms ease-in-out 0ms;
   width: 100%;
}

.overlay-section {
   display: flex;
   flex-direction: column;
}

body.overlay-open .overlay {
   opacity: 1;
   pointer-events: auto;
   transition: opacity 800ms ease-in-out 0ms;
}

body.overlay-open .overlay-inner {
   opacity: 1;
   transition: all 800ms ease-in-out 800ms;
}

.column {
   width: 100%;
}

.column.half {
   width: 100%;
}

.column-header {
   font-family: 'Manrope-ExtraBold';
   font-size: 20px;
   margin: 0 0 20px 0;
   text-transform: uppercase;
}

.column-content {
   display: flex;
   flex-direction: column;
   margin-bottom: 50px;
}

.project-link {
   border: 2px solid #f9f9f9;
   color: #f9f9f9;
   display: flex;
   font-family: 'Manrope-ExtraBold';
   flex: 1;
   padding: 20px;
   margin: 10px 0;
   text-transform: uppercase;
   text-decoration: none;
   transition: all 300ms ease-in-out;
}

.project-link:hover {
   background: #f9f9f9;
   border: 2px solid #f9f9f9;
   color: #111;
}

.email {
   padding: 5px 0;

}

.resume {
   color: #f9f9f9;
   text-decoration: underline;
   margin: 15px 0;
   padding: 5px 0;
}

.social {
   display: flex;
   margin-top: 15px;
}

.social-icon {
   cursor: pointer;
   display: block;
   height: 40px;
   overflow: hidden;
   padding: 5px;
   text-indent: -1000em;
   width: 40px;
}


.social-icon.github {
   background: url('../../../images/icons/github.png') center center no-repeat;
   background-size: 90% auto;
   margin-right: 15px;
}

.social-icon.linkedin {
   background: url('../../../images/icons/linkedin.png') center center no-repeat;
   background-size: 90% auto;
}

.blurb {
   margin: 0;
}


body.overlay-open .button-overlay-open {
   filter: blur(2px);
}

@media screen and (min-width: 1000px) {

   .overlay-inner {
      padding: 120px 60px 90px 60px;
   }

   .overlay-section {
      flex-direction: row;
   }

   .column.half {
      width: 50%;
   }

   .column-content {
      flex-direction: row;
   }

   .column-content.contact {
      align-items: center;
      flex-wrap: wrap;
   }

   .resume {
      margin: 0 30px;
   }

   .social {
      margin-top: 0;
   }

   .project-link {
      margin: 0 10px;
   }

   .project-link:first-of-type {
      margin-left: 0;
   }

   .project-link:last-of-type {
      margin-right: 0;
   }

}
