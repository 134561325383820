.button {
   background: #111;
   border: 2px solid #f3f3f3;
   color: #f3f3f3;
   cursor: pointer;
   display: inline-block;
   font-family: 'Manrope-ExtraBold';
   font-size: 11px;
   margin-top: 10px;
   padding: 12px 4px;
   position: relative;
   text-align: center;
   text-decoration: none;
   text-transform: uppercase;
   transition: all 300ms ease-in-out;
   width: 180px;
}

.button:hover {
   background: #f3f3f3;
   color: #111;
}

@media screen and (min-width: 1000px) {

   .button {
      margin-top: 20px;
   }

}
