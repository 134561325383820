.leader {
   margin-bottom: 30px;
}

.leader-line {
   font-family: 'Manrope-Bold';
   font-size: 22px;
   line-height: 30px;
   margin: 20px 0 0 0;
}

.leader-line:first-of-type {
   margin-top: 0;
}

@media screen and (min-width: 1000px) {

   .leader {
      margin-bottom: 60px;
   }

   .leader-line {
      font-size: 33px;
      line-height: 40px;
      max-width: 580px;
   }

}
