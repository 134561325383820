.sample {
   margin: 90px 0;
   opacity: 0;
   transition: all 800ms ease-in-out;
   width: 100%;
}

.sample.in-view {
   opacity: 1;
}

.sample-media {
   flex: 1;
   position: relative;
}

.sample-media .image {
   display: block;
   width: 100%;
}

.sample-media .video {
   display: block;
   width: 100%;

}

.sample-copy {
   flex: 1;
   margin-top: 25px;
}

.sample-copy-title {
   font-family: "Manrope-ExtraBold";
   font-size: 24px;
   letter-spacing: 0px;
   line-height: 1;
   text-transform: uppercase;
   margin: 0;
}

.sample-copy-blurb {
   margin: 15px 0;
}


@media screen and (min-width: 1000px) {

   .sample {
      display: flex;
      flex-direction: row-reverse;
      margin: 200px 0;
   }

   .sample:nth-child(odd) {
      flex-direction: row;
   }

   .sample-media {
      flex: 3;
   }

   .sample-copy {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      padding-right: 60px;
   }

   .sample:nth-child(odd) .sample-copy {
      padding-left: 60px;
      padding-right: 0;
   }

   .sample-copy-step {
      margin-bottom: 15px;
   }

   .sample-copy-title {
      font-size: 28px;
      margin: 0;
      max-width: 250px;
   }

   .sample-copy-blurb {
      margin: 15px 0 0 0;
   }


}
