/* !FONT EMBEDS ----------------------------------------------------------------
----------------------------------------------------------------------------- */

@font-face {
   font-family: 'Manrope';
   src: local('Manrope'), url(./fonts/Manrope-Regular.woff2) format('woff2');
}

@font-face {
   font-family: 'Manrope-Light';
   src: local('Manrope-Light'), url(./fonts/Manrope-Light.woff2) format('woff2');
}

@font-face {
   font-family: 'Manrope-Medium';
   src: local('Manrope-Medium'), url(./fonts/Manrope-Medium.woff2) format('woff2');
}

@font-face {
   font-family: 'Manrope-Bold';
   src: local('Manrope-Bold'), url(./fonts/Manrope-Bold.woff2) format('woff2');
}

@font-face {
   font-family: 'Manrope-ExtraBold';
   src: local('Manrope-ExtraBold'), url(./fonts/Manrope-ExtraBold.woff2) format('woff2');
}
