.masthead-text {
   display: flex;
   flex-direction: column;
   height: 450px;
   justify-content: flex-end;
   letter-spacing: 1px;
   margin: 0 auto;
   max-width: 1400px;
   overflow: hidden;
   padding: 30px;
   width: 100%;
   z-index: 1;
}

.masthead-text-header {
   font-family: "Manrope-ExtraBold";
   font-size: 48px;
   letter-spacing: -1px;
   line-height: 48px;
   margin: 0;
   position: relative;
   text-transform: uppercase;
   white-space: pre-line;
}

.masthead-text-header:first-of-type {
   margin-top: 0;
}

.masthead-text-header sup {
   font-size: 20px;
}

.masthead-text-subhead {
   font-family: "Manrope-ExtraBold";
   font-size: 12px;
   margin: 5px 0;
   position: relative;
}


@media screen and (min-width: 1000px) {

   .masthead-text {
      height: 52vh;
      padding: 60px;
   }

   .masthead-text-header {
      font-size: 90px;
      letter-spacing: 2px;
      line-height: 88px;
   }

   .masthead-text-subhead {
      max-width: 300px;
   }

}
