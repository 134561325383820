.footer {
   transition: filter 600ms ease-in-out;
   width: 100%;
}

body.overlay-open .footer {
   filter: blur(2px);
}

.footer-inner {
   display: flex;
   justify-content: space-between;
   margin: 0 auto;
   max-width: 1400px;
   padding: 30px;
   width: 100%;
}

.footer, .footer small {
   font-size: 10px;
}


@media screen and (min-width: 1000px) {

   .footer {

   }

   .footer-inner {
      padding: 60px;
   }

}
