.preview {
   background: #767676;
   height: 230px;
   margin: 15px 0;
   overflow: hidden;
   opacity: 0;
   position: relative;
   transition: all 800ms ease-in-out;
   width: 100%;
}

.preview.in-view {
   opacity: 1;
}

.preview-copy {
   bottom: 15px;
   position: absolute;
   right: 15px;
   text-align: right;
   text-decoration: none;
   z-index: 1;
}

.preview-copy-title {
   color: #f3f3f3;
   font-family: "Manrope-ExtraBold";
   font-size: 28px;
   line-height: 1;
   margin: 0;
   text-transform: uppercase;
   transition: all 300ms ease-in-out;
   white-space: pre-line;
}

.preview-copy:hover .preview-copy-title {
   color: #111;
}

.preview-copy-label {
   background: #111;
   border: 2px solid #111;
   color: #f3f3f3;
   cursor: pointer;
   display: inline-block;
   font-family: 'Manrope-ExtraBold';
   font-size: 11px;
   margin-top: 15px;
   padding: 12px 4px;
   position: relative;
   text-align: center;
   text-transform: uppercase;
   transition: all 300ms ease-in-out;
   width: 150px;
}

.preview-visual {
   filter: brightness(40%);
   height: 100%;
   right: 0;
   opacity: 0.8;
   position: absolute;
   top: 0;
   transform: translateX(40px);
   transition: transform 1800ms ease-out, opacity 300ms ease-out, filter 300ms ease-out;
   width: calc(100% + 40px);
}

.preview-copy:hover ~ .preview-visual {
   filter: brightness(100%);
   transform: translateX(0);
   transition: transform 3000ms ease-out, opacity 300ms ease-out, filter 300ms ease-out;
   opacity: 1;
}

.clayton .preview-visual {
   background: url('../../../images/clayton/preview-mobile.jpg') center center no-repeat;
   background-size: cover;
}

.beats .preview-visual {
   background: url('../../../images/beats/preview-mobile.jpg') center center no-repeat;
   background-size: cover;
}

.giide .preview-visual {
   background: url('../../../images/giide/preview-mobile.jpg') right center no-repeat;
   background-size: cover;
}

.fridays .preview-visual {
   background: url('../../../images/fridays/preview-mobile.jpg') center center no-repeat;
   background-size: cover;
}

.diageo .preview-visual {
   background: url('../../../images/diageo/preview-mobile.jpg') left bottom no-repeat;
   background-size: cover;
}


@media screen and (min-width: 700px) {

   .preview {
      height: 410px;
      margin: 30px 0 30px 0;
   }

   .preview-copy {
      bottom: 30px;
      left: 30px;
      right: auto;
      text-align: left;
   }

   .preview-copy-title {
      font-size: 60px;
      letter-spacing: 1px;
   }

   .preview-copy-link {
      margin-top: 30px;
   }

   .clayton .preview-visual {
      background: url('../../../images/clayton/preview-desktop.jpg') right center no-repeat;
      background-size: cover;
   }

   .beats .preview-visual {
      background: url('../../../images/beats/preview-desktop.jpg') right bottom no-repeat;
      background-size: cover;
   }

   .giide .preview-visual {
      background: url('../../../images/giide/preview-desktop.jpg') right center no-repeat;
      background-size: cover;
   }

   .fridays .preview-visual {
      background: url('../../../images/fridays/preview-desktop.jpg') right center no-repeat;
      background-size: cover;
   }

   .diageo .preview-visual {
      background: url('../../../images/diageo/preview-desktop.jpg') right center no-repeat;
      background-size: cover;
   }

}

@media screen and (min-width: 1200px) {

   .preview {

   }

}
